import initRR, {
  setGenericPageContext,
  getPlacementData,
  buildRRClickHandler,
  loadScript,
} from '@rei/recommendations';

const placementId = 'search_page.RecentlyViewedSearchBox';

export default async (receivePlacement) => {
  function processPlacement() {
      const placementData = getPlacementData(placementId);
      const formattedPlacement =
        {
          ...placementData,
          items: placementData.items.map(item => ({...item, name: item.name ? item.name : ''})),
          clickHandler: (event) => {
            const handler = buildRRClickHandler(window?.metrics?.link);
            handler(event);
          },    
        };
      receivePlacement(formattedPlacement)
  }
  if (!window.r3_common) {
    await loadScript();
  }
  initRR({
    placements: [placementId],
    pageContextCB: setGenericPageContext,
    rrCallback: processPlacement,
  });
};