
<template>
  <div id="search-recommendations-slider">
    <div v-if="!placement && !error" class="search-recommendations__skeleton">
      <cdr-skeleton-bone class="search-recommendations__skeleton-bone" type="line" />
      <div class="search-recommendations__skeleton-container">
      <cdr-skeleton v-for="index in 4" :key="index">
        <cdr-card class="search-recommendations__skeleton-card">
          <section>
            <cdr-skeleton-bone type="rectangle" />
            <div class="inset">
              <cdr-skeleton-bone type="heading" />
              <div class="search-recommendations__skeleton-card-body">
                <cdr-skeleton-bone class="search-recommendations__skeleton-bone" type="line" />
                <cdr-skeleton-bone class="search-recommendations__skeleton-bone" type="line" />
                <cdr-skeleton-bone class="search-recommendations__skeleton-bone" type="line" />
              </div>
            </div>
          </section>
        </cdr-card>
      </cdr-skeleton>
    </div>
    </div>
    <div v-if="placement && !error" class="search-recommendations__slider">
      <RecommendationsSlider
          v-bind="placement"
          id="search-recently-viewed-slider"
          :key="placement.message"
          :slider-with-arrows="true"
          :strategy="placement.strategy"
          :placement-name="placement.placementName"
          :max-tiles="numberOfTilesVisible"
          :hide-dots="true">
        <template #heading>
          <h3 ref="recommendationsSlider" class="search-recommendations__slider-header">
            {{ placement.message }}
          </h3>
        </template>
      </RecommendationsSlider>
    </div>
  </div>
</template>
  
<script>
// TODO: If the recs slider wins, we should pursue pulling this in as a Vue Async component
import RecommendationsSlider from '@rei/recommendations-slider';
import { CdrSkeleton, CdrSkeletonBone, CdrCard } from '@rei/cedar';
import getRecommendations from '../getRecommendations';

export default {
  name: 'SearchBoxRecentlyViewed',
  components: {
    RecommendationsSlider,
    CdrSkeleton,
    CdrSkeletonBone,
    CdrCard,
  },
  data() {
    return {
      placement: null,
      error: null,
      observer: null,
    };
  },
  computed: {
    numberOfTilesVisible() {
      if (window.innerWidth > 768) {
        return 4;
      } 
        return 2;
    },
  },
  mounted() {
    const searchInput = document.querySelector(".search__body");
    const resultsDropdown = document.querySelector(".recent-search-box");
    // we add this observer to handle users opening and closing the search box
    // it helps us to avoid keeping the document event listener around any longer
    // than it needs to be around
    const mutationObserverConfig = { attributes: true };
    const callback = (mutationList) => {
      /* eslint-disable no-plusplus */
      for (let i = 0; i < mutationList.length; i++) {
        const mutation = mutationList[i];
        if (mutation.attributeName === "data-visible") {
          if (mutation.target.dataset.visible === 'visible') {
            document.addEventListener('click', this.handleSliderClickBehavior);
          }
        }
      }
    }
    this.observer = new MutationObserver(callback);
    this.observer.observe(resultsDropdown, mutationObserverConfig);
    if (!this.placement) {
      this.fetchPlacementData();
    };
    // Some click behavior is handled in Common Components Site, however
    // when you click on an arrow, the focus is no longer on the input,
    // so the blur event handled in common components site on the input does not fire to close 
    // the menu when you click out of the search box
    // this will keep results open when clicking or focusing inside the search results box,
    // or close the box when clicking outside of it
    this.handleSliderClickBehavior = (e) => {
      if (searchInput.contains(e.target)) {
        resultsDropdown.setAttribute('data-visible', 'visible');
      }
      else {
        resultsDropdown.setAttribute('data-visible', 'hidden');
        document.removeEventListener('click', this.handleSliderClickBehavior);
      }
    };
    // checks if the event target is inside or outside of the slider
    document.addEventListener('click', this.handleSliderClickBehavior);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleSliderClickBehavior);
    this.observer.disconnect();
  },
  methods: {
    setPlacement(placementData) {
      this.placement = placementData;
    },
    fetchPlacementData() {
      try {
        getRecommendations((placement) => {
          this.setPlacement(placement);
          const { strategy } = placement;
          window?.metrics?.event({ linkName: strategy });
        });
      } catch (error) {
        this.error = true;
        console.log(error);
      }
    },
  },
}; 
</script>
  
<style lang="scss">
// @rei/carousel is a depenency of @rei/recommendations-slider
@import '@rei/carousel/dist/style.css';
@import '@rei/recommendations-slider/dist/style.css';
@import "@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens.scss";

// only show the last 4 recent searches on both variant and control
li.searchbox-result:nth-child(1n + 6) { 
  display: none;
}

.search-recommendations { 
  list-style-type: none;
  background-color: white;
  border: 1px solid $cdr-color-border-primary; 
  border-top: 0;
  &__slider {
    @media (max-width: 767px) {
      ul {
        margin-left: 0;
          li:first-child {
            padding-left: 0;
        }
      }
    }
    padding:10px;
    &-header {
      @include cdr-text-heading-sans-300;
    }
    .product-tile__rating {
      display: none;
    }
  }
  &__loader {
    height: 200px;
  }
  &__skeleton {
    padding: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    &-container {
      display:flex;
      flex-direction: row;
    }
    &-card {
      width: 105.5px;
      margin-right: 10px;
    }
    &-bone {
      height: 12px;
      &-header {
        width: 30%;
      }
    }
  }
  h3.product-tile__title {
    line-height: 1.75rem;
  }
  span.product-tile__title--name {
    @include cdr-text-utility-sans-200;
  }

  span.product-tile__title--brand {
    @include cdr-text-heading-sans-200;
  }
  div.product-tile {
    padding: 10px;
  }
}
</style>
