import { createApp } from 'vue';
import mitt from 'mitt';
import NavButton from './components/NavButton.vue';
import MyStoreBackdrop from './components/MyStoreBackdrop.vue';
import MyStoreComponent from './components/MyStoreComponent.vue';
import SearchBoxRecentlyViewed from './components/SearchBoxRecentlyViewed.vue';
import AdobeTarget from './utils/adobe-target';

import './account';

const gNav = document.querySelector('.gnav');
const isWhiteNav = gNav !== null;

let isMyStoreMounted = false;
const globalEmitter = mitt();

function hasSearchHistory() {
  const searchList = document.querySelector('.search__body');
  const allChildElementsWithId = searchList?.querySelectorAll('[id]');
  // slider only gets shown when other search history results are present
  return Array.from(allChildElementsWithId).some(element => 
    element.id.includes('search-history-entry-')
  );
}

const searchBox = document.querySelector('.search__input');
async function addRecommendations() {
  let searchRecentlyViewedExperience = 'control';
  try {
    const searchRecentlyViewedTest = await AdobeTarget.get('search-recently-viewed');
    if (searchRecentlyViewedTest) {
      searchRecentlyViewedExperience = searchRecentlyViewedTest;
    }
  } catch (e) {
    console.error('Error with getAdobeTargetJsonOffer', e);
  }
  if (searchRecentlyViewedExperience !== 'variant1' || !hasSearchHistory()) {
    // eslint-disable-next-line no-use-before-define
    searchBox?.removeEventListener('focusin', handleFocusIn);
    return;
  }
  const newSearchItem = document.createElement('div');
  const resultsDropdown = document.querySelector(".recent-search-box");
  newSearchItem.setAttribute('id', 'search-recommendations');
  newSearchItem.setAttribute('class', 'search-recommendations');
  resultsDropdown.appendChild(newSearchItem);
  createApp(SearchBoxRecentlyViewed).mount("#search-recommendations");
  // eslint-disable-next-line no-use-before-define
  searchBox?.removeEventListener('focusin', handleFocusIn);
}
function yieldToMain () {
  return new Promise(resolve => {
    setTimeout(resolve, 0);
  });
}
async function handleFocusIn() {
  await yieldToMain();
  addRecommendations();
}
searchBox?.addEventListener('focusin', handleFocusIn);

function addBackdrop() {
  const secondaryNav = document.querySelector('[data-js="secondary-nav"]');
  const backdrop = document.createElement('div');
  backdrop.setAttribute('id', 'my-store-backdrop');
  secondaryNav.appendChild(backdrop);

  createApp(MyStoreBackdrop).mount('#my-store-backdrop');
}

function addNavButton() {
  const defaultMyStoreLink = document.querySelector('[data-ui="site-header-action-local"]');

  if (defaultMyStoreLink) {
    const defaultMyStoreLinkContainer = defaultMyStoreLink.parentNode;
    defaultMyStoreLinkContainer.replaceChildren();

    const navButtonApp = createApp(NavButton);
    // needs to hook into shared emitter to display selected store
    navButtonApp.provide('globalEmitter', globalEmitter).mount(defaultMyStoreLinkContainer);
  }
}

if (isWhiteNav) {
  addBackdrop();
  addNavButton();
}

const myStoreToggleButton = document.querySelector("[data-ui='site-header-action-local']");
const navbarWrapper = document.querySelector("[data-ui='site-navigation']");

// Mount mystore and set event listeners, this function will only be executed
// if my storeToggleButton is hover or focused
// Otherwise there is no need to mount component
const mountMyStore = () => {
  sessionStorage.setItem('myStoreOpen', 'false');
  isMyStoreMounted = true;
  if (myStoreToggleButton) {
    // Insert <li> element next to storeToggle button to render MyStore component
    myStoreToggleButton.parentNode.insertAdjacentHTML(
      'afterEnd',
      `<li class="stores-dropdown ${
        isWhiteNav && 'stores-dropdown--white-nav'
      }"><div id="my-store-placement"></div></li>`,
    );

    const myStoreApp = createApp(MyStoreComponent);
    myStoreApp.provide('globalEmitter', globalEmitter);

    myStoreApp.mount('#my-store-placement');
  }
};

if (myStoreToggleButton && navbarWrapper) {
  if (isWhiteNav) {
    myStoreToggleButton.parentNode.removeAttribute('data-visible');
  }

  ['focus', 'mouseenter'].forEach((eventName) =>
    myStoreToggleButton.addEventListener(
      eventName,
      () => {
        if (!isMyStoreMounted) {
          mountMyStore();
        }
      },
      {
        once: true,
      },
    ),
  );

  window.addEventListener('load', () => {
    if (!isMyStoreMounted) {
      mountMyStore();
    }
  });
}
