<template>
  <SignInNudge
    :open="open"
    :trap-tab-inside-widget="false"
    @closed="closed"
    @on-escape="onEscape"
  />
</template>

<script>
import mitt from 'mitt';
import { SignInNudge } from '@rei/account-flyout';
import { ref } from 'vue';

const signInNudgeEventBus = mitt();
const gNav = document.querySelector('.gnav');
const isWhiteNav = gNav !== null;
const signInLink = document.querySelector("[data-ui='login-module-greeting']");

export default {
  name: 'SignInNudgeComponent',
  components: { SignInNudge },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const open = ref(false);
    const isDesktop = ref(window.matchMedia('(min-width: 992px)').matches);
    const signInNudge = document.querySelector('.sign-in-nudge-dropdown');
    const navbarWrapper = document.querySelector("[data-ui='site-navigation']");

    function toggle() {
      open.value = !open.value;
    }

    function handleClose() {
      open.value = false;
      signInLink.setAttribute('aria-expanded', 'false');
      navbarWrapper.style.overflowY = 'initial';
      const nudgeClasses = [
        'sign-in-nudge__container--flyout-login',
        'sign-in-nudge__container--flyout-registration-link',
      ];
      const isFocusOnNudge = nudgeClasses.indexOf(document.activeElement.classList[0]) > -1;
      if (isFocusOnNudge) {
        signInLink.focus();
      }

      if (isWhiteNav) {
        gNav.setAttribute('data-nudge', 'false');
        document.body.classList.remove('no-scroll');
        setTimeout(
          () => {
            signInNudge.style.display = 'none';
          },
          isDesktop.value ? 0 : 400,
        );
      }
    }

    function clickOutsideHandler(event) {
      const excludeElements = ['login-module-greeting'];

      if (
        !open.value ||
        excludeElements.includes(event.target.getAttribute('data-ui')) ||
        signInLink.contains(event.target)
      )
        return;

      if (!signInNudge.contains(event.target)) {
        signInNudgeEventBus.emit('closed');
      }
    }

    function openSignInNudge() {
      const { clientWidth } = window.document.body;
      toggle();
      document.body.classList.remove('sign-in-open');
      signInLink.setAttribute('aria-expanded', 'true');
      navbarWrapper.style.overflowY = clientWidth < 992 ? 'hidden' : 'initial';
      signInNudge.style.display = 'block';

      if (isWhiteNav && isDesktop.value) {
        gNav.setAttribute('data-nudge', 'true');
        document.body.classList.add('no-scroll');
      }
    }

    function checkTimeSinceNudge(lastOpenedKey) {
      const thirtyMinuteMs = 30 * 60 * 1000;
      const thirtyMinutesAgo = +new Date() - thirtyMinuteMs;

      const nudgeLastOpened = parseInt(window.localStorage.getItem(lastOpenedKey), 10);

      const openedWithinLast30Min = nudgeLastOpened > thirtyMinutesAgo;

      if (openedWithinLast30Min || props.user.isLoggedIn) return;

      window.localStorage.setItem(lastOpenedKey, String(+new Date()));
      openSignInNudge();
    }

    function shouldSignInNudgeLoad() {
      setTimeout(() => {
        const initialPropsHTML = document.getElementById('initial-props')?.innerHTML;
        const initialProps = initialPropsHTML && JSON.parse(initialPropsHTML);
        const isSearchPage = !!initialProps?.ProductSearch;
        const mainLastOpenedKey = 'login-opened';
        const cartLastOpenedKey = 'cart-opened';
        const isHomePage = ['', '/'].indexOf(window.location.pathname) > -1;
        const isCorePDP = !!window.location.pathname.match(/^\/product/);
        const isCart = ['', '/ShoppingCart'].indexOf(window.location.pathname) > -1;
        const myStoreOpen = sessionStorage.getItem('myStoreOpen') === 'true';
        if (myStoreOpen) return;
        if (!(isHomePage || isCorePDP || isCart || isSearchPage)) return;
        if (isHomePage || isCorePDP || isSearchPage) checkTimeSinceNudge(mainLastOpenedKey);
        if (isCart) checkTimeSinceNudge(cartLastOpenedKey);
      }, 1000);
    }

    function closed() {
      signInNudgeEventBus.emit('closed');
    }

    function onEscape() {
      signInNudgeEventBus.emit('closed');
    }

    return {
      open,
      isDesktop,
      toggle,
      handleClose,
      clickOutsideHandler,
      checkTimeSinceNudge,
      shouldSignInNudgeLoad,
      openSignInNudge,
      closed,
      onEscape,
    };
  },
  mounted() {
    this.shouldSignInNudgeLoad();
    signInNudgeEventBus.on('closed', this.handleClose);

    signInLink.setAttribute('aria-expanded', 'false');
    signInLink.setAttribute('aria-controls', 'sign-in-nudge');
    window.addEventListener('click', this.clickOutsideHandler, true);
  },
  beforeUnmount() {
    signInNudgeEventBus.off('closed', this.handleClose);
    window.removeEventListener('click', this.clickOutsideHandler, true);
  },
};
</script>
